<template>
  <div
    class="logo"
    :class="{ 'logo--inverted': (mode === 'inverted') }"
  >
    <div class="logo__image-container">
      <a
        v-if="filetype !== 'ai'"
        :href="src"
      >
        <img
          :src="src"
          class="logo__image"
          :class="{ 'logo__image--svg': (filetype === 'svg') }"
        >
      </a>
      <img
        v-else
        src="../assets/illustrator.svg"
        class="logo__image--ai"
      >
    </div>
    <div class="logo__info">
      <p>Dateiname: {{ filename }}</p>
      <p>Dimension: {{ dimensions }} px</p>
      <p>Dateityp: {{ filetype }}</p>
      <p>Grafikart: {{ kind }}</p>
    </div>
    <div class="logo__download">
      <a
        :href="src"
        download
        title="download"
      >
        <svg
          viewBox="0 0 24 24"
        >
          <path
            fill="currentColor"
            d="M5,20H19V18H5M19,9H15V3H9V9H5L12,16L19,9Z"
          />
        </svg>
      </a>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Logo',
  props: {
    dimensions: {
      type: String,
      required: true,
    },
    filetype: {
      type: String,
      required: true,
    },
    filename: {
      type: String,
      required: true,
    },
    mode: {
      type: String,
      required: true,
    },
    kind: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      folder: '/logos/',
    };
  },
  computed: {
    src() {
      return `${this.folder + this.kind}/${this.filename}`;
    },
  },
};
</script>

<style lang="scss">
$component: '.logo';

#{$component} {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  background-color: white;
  color: #333;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);

  &:not(:last-of-type) {
    margin-bottom: 6rem;
  }

  &__image-container {
    padding-bottom: 1rem;
  }

  &__image {
    width: auto;
    max-width: 100%;

    &--ai {
      width: 3rem;
    }

    &--svg {
      width: 100%;
    }
  }

  &__info {

    p:not(:last-of-type) {
      margin-bottom: .5rem;
    }
  }

  &__download {
    position: absolute;
    right: 2rem;
    bottom: 2rem;
    width: 3rem;
    height: 3rem;

    a {
      color: #666;
      transition: color .3s ease-in;

      &:hover {
        color: black;
        transition: color .3s ease-in;
      }
    }

    svg {
      width: 100%;
      height: 100%;
    }

    @media only screen and (min-width: 600px) {
      width: 5rem;
      height: 5rem;
    }
  }

  &--inverted {
    background-color: #666;
    color: white;

    #{$component}__download {

      a {
        color: #DDD;
        transition: color .3s ease-in;

        &:hover {
          color: white;
          transition: color .3s ease-in;
        }
      }

    }
  }
}
</style>
