<template>
  <div class="content">
    <div class="filter">
      <div class="filter__element filter__element--select">
        <label for="filetype">Dateitypen</label>
        <select
          id="filetype"
          v-model="filetypeSelected"
          name="filetype"
        >
          <option value="">
            alle
          </option>
          <option
            v-for="(filetype, index) in filetypes"
            :key="index"
            :value="filetype"
          >
            {{ filetype }}
          </option>
        </select>
      </div>
      <div class="filter__element filter__element--select">
        <label for="filetype">Grafikarten</label>
        <select
          id="kind"
          v-model="kindSelected"
          name="kind"
        >
          <option value="">
            alle
          </option>
          <option
            v-for="(kind, index) in kinds"
            :key="index"
            :value="kind"
          >
            {{ kind }}
          </option>
        </select>
      </div>

      <div class="filter__element filter__element--select">
        <label for="mode">Modi</label>
        <select
          id="mode"
          v-model="modeSelected"
          name="mode"
        >
          <option value="">
            alle
          </option>
          <option
            v-for="(mode, index) in modes"
            :key="index"
            :value="mode"
          >
            {{ mode }}
          </option>
        </select>
      </div>
    </div>
    <Logo
      v-for="(logo, index) in filteredLogos"
      :key="index"
      :dimensions="logo.dimensions"
      :filetype="logo.filetype"
      :filename="logo.filename"
      :mode="logo.mode"
      :kind="logo.kind"
    />
  </div>
</template>

<script>
import logos from '../data/logos.json';
import Logo from './Logo.vue';

export default {
  name: 'Content',
  components: {
    Logo,
  },
  data() {
    return {
      logos,
      filetypeSelected: '',
      kindSelected: '',
      modeSelected: '',
      filterByMode: false,
      filterByKind: false,
    };
  },
  computed: {
    filetypes() {
      return [...new Set(this.logos.map((item) => item.filetype))];
    },
    modes() {
      return [...new Set(this.logos.map((item) => item.mode))];
    },
    kinds() {
      return [...new Set(this.logos.map((item) => item.kind))];
    },
    filteredLogos() {
      let localLogos = this.logos;

      if (this.filetypeSelected !== '') {
        localLogos = localLogos.filter((logo) => logo.filetype === this.filetypeSelected);
      }

      if (this.kindSelected !== '') {
        localLogos = localLogos.filter((logo) => logo.kind === this.kindSelected);
      }

      if (this.modeSelected !== '') {
        localLogos = localLogos.filter((logo) => logo.mode === this.modeSelected);
      }

      return localLogos;
    },
  },
};
</script>

<style lang="scss">
.content {
  width: 100%;
  margin-top: 6.5rem;
  padding: 2rem;

  @media screen and (min-width: 600px) {
    margin-top: 8rem;
  }
}

.filter {
  display: flex;
  justify-content: space-around;
  margin-bottom: 3rem;

  &__element {
    display: flex;
    flex-direction: column;

    &--select {

      select {
        font-size: 1.6rem;
        font-weight: 300;
        padding: .5rem;
      }

      label {
        margin-bottom: .5rem;
        color: #333;
      }
    }
  }
}
</style>
