<template>
  <Header />
  <Content />
</template>

<script>
import Header from './components/Header.vue';
import Content from './components/Content.vue';

export default {
  name: 'App',
  components: {
    Header,
    Content,
  },
};
</script>

<style lang="scss">

* {
    margin: 0;
    padding: 0;
}

*,
*::after,
*::before {
    box-sizing: inherit;
}

html {
    box-sizing: border-box;
    font-size: 62.5%; // 1 rem = 10 px (10px/16px = 62.5%)

    @media only screen and (max-width: 960px) {
      font-size: 50%;
    }
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  font-size: 1.6rem;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #efefef;
}

#app {
  max-width: 1650px;
  margin: 0 auto;
}

h1, h2, h3 {
  font-weight: 400;
}

img,
svg {
  display: block;
}
</style>
