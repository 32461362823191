<template>
  <div class="header">
    <div class="header__left">
      <a
        href="/"
        class="header__link"
      >
        <img
          class="header__logo"
          alt="Kupper Solutions logo"
          src="@/assets/logo.svg"
        >
      </a>
    </div>
    <div class="header__right">
      <h1 class="header__title">
        Kupper Solution Logos
      </h1>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Header',
};
</script>

<style lang="scss">
.header {
  width: 100%;
  height: 6.5rem;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  justify-content: space-between;
  background-color: #efefef;
  padding: 2rem;
  background-color: black;
  color: white;
  box-shadow: 0px 5px 13px -3px rgba(0,0,0,0.75);

  @media screen and (min-width: 600px) {
    height: 8rem;
  }

  &__left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &__right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &__link {
    text-decoration: none;
  }

  &__logo {
    width: 15rem;

    @media screen and (min-width: 600px) {
      width: 20rem;
    }
  }

  &__title {
    font-size: 2rem;

    @media screen and (min-width: 600px) {
      font-size: 3rem;
    }
  }

}
</style>
